<template>
  <img class="h-full w-auto object-cover object-center" :src="require('./images/website_img.jpg')"/>
</template>

<script>

export default {
  name: "ImageDisplay",
}
</script>

<style scoped>
</style>